import * as React from "react";
import { Fragment, useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Paper,
  TextField,
} from "@mui/material";
import { LockOutlined, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  avatarStyle,
  boxStyle,
  formStyle,
  paperSignStyle,
  submitStyle,
} from "./style";
import {
  selectStatus,
  selectUser,
  submitConfirmationCodeAsync,
} from "./sessionSlice";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

type UpdatePasswordProps = {
  type: string;
};

export const UpdatePassword = (props: UpdatePasswordProps) => {
  const { t, i18n } = useTranslation();
  const [searchParams] = useSearchParams();
  const previouslyTypedUsername =
    useAppSelector(selectUser) || searchParams.get("email") || "";
  const [oldPassword, setOldPassword] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [passwordsDoNotMatch, setPasswordsDoNotMatch] = useState(false);
  const [passwordInvalid, setPasswordInvalid] = useState(false);
  const [viewOldPassword, setViewOldPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const confirmationCodeStatus = useSelector(selectStatus);
  const dispatch = useAppDispatch();

  const _t = React.useCallback(
    (key: string) => {
      const fullKey = `UpdatePassword.${props.type}.${key}`;
      let translation = t(fullKey);
      if (translation === fullKey) {
        translation = t(`UpdatePassword.GENERIC.${key}`);
      }
      return translation;
    },
    [props.type, t]
  );

  const PasswordPolicyError = () => {
    return (
      <div>
        <Typography sx={{ fontSize: "0.85rem" }}>
          {_t("errors.passwordInvalid.constraintsRequired")}
        </Typography>
        <ul style={{ fontSize: "0.85rem" }}>
          <li>{_t("errors.passwordInvalid.length")}</li>
          <li>{_t("errors.passwordInvalid.uppercase")}</li>
          <li>{_t("errors.passwordInvalid.lowercase")}</li>
          <li>{_t("errors.passwordInvalid.number")}</li>
          <li>
            {_t("errors.passwordInvalid.special1")}
            <br />
            {_t("errors.passwordInvalid.special2")}
          </li>
        </ul>
      </div>
    );
  };

  const PasswordMatchError = () => {
    return (
      <Typography sx={{ fontSize: "0.85rem" }}>
        {_t("errors.passwordsDoNotMatch")}
      </Typography>
    );
  };

  const checkPwPolicy = (checkedPassword: string) => {
    const pw = checkedPassword;
    // Between 8 and 99 characters
    let ret = pw.length >= 8 && pw.length <= 99;
    // At least one number
    ret &&= /[0-9]/gm.test(pw);
    // At least one of ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \ , > < ' : ; | _ ~ `
    ret &&=
      /(\^|\$|\*|\.|\[|\]|{|}|\(|\)|\?|-|"|!|@|#|%|&|\/|\\|,|>|<|'|:|;|\||_|~|-|`)/gm.test(
        pw
      );
    // At least one lower case
    ret &&= pw.toUpperCase() !== pw;
    // At least one upper case
    ret &&= pw.toLowerCase() !== pw;
    return ret;
  };

  React.useEffect(() => {
    if (searchParams.get("lang")) {
      i18n.changeLanguage(searchParams.get("lang") || "fr");
    }
  }, [i18n, searchParams]);

  React.useEffect(() => {
    if (confirmationCodeStatus === "submit-confirmation-code-rejected") {
      enqueueSnackbar(_t("errors.oldPasswordWrong"), {
        autoHideDuration: 5000,
        variant: "warning",
      });
    }
    if (confirmationCodeStatus === "submit-confirmation-code-idle") {
      enqueueSnackbar(_t("success.passwordChanged"), {
        autoHideDuration: 5000,
        variant: "success",
      });
      navigate("/");
    }
  }, [_t, enqueueSnackbar, navigate, confirmationCodeStatus]);

  React.useEffect(() => {
    setPasswordInvalid(!checkPwPolicy(password));
    setPasswordsDoNotMatch(password !== repeatPassword);
  }, [password, repeatPassword]);

  const toggleOldPasswordVisibility = () => {
    setViewOldPassword(!viewOldPassword);
  };

  const toggleNewPasswordVisibility = () => {
    setViewNewPassword(!viewNewPassword);
  };

  const updateOldPassword = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setOldPassword(event.target.value);
  };

  const updatePassword = (event: {
    target: { value: string };
  }) => {
    setPassword(event.target.value);
  };

  const updateRepeatPassword = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setRepeatPassword(event.target.value);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    dispatch(
      submitConfirmationCodeAsync({
        username: previouslyTypedUsername,
        recoveryCode: oldPassword,
        password,
        initAccount: props.type === "INITIALIZE",
      })
    );
  };

  return (
    <Box sx={boxStyle}>
      <Paper className="paperSign" sx={paperSignStyle}>
        <Avatar className="avatar" sx={avatarStyle}>
          <LockOutlined />
        </Avatar>
        <Fragment>
          <Typography component="h1" variant="h5">
            {_t("title")}
          </Typography>
          {_t("subtitle") && (
            <Typography component="h6" variant="subtitle1">
              {_t("subtitle")}
            </Typography>
          )}
          <form className="Form" onSubmit={handleSubmit}>
            <TextField
              disabled
              margin="normal"
              required
              fullWidth
              variant="standard"
              label={_t("username")}
              sx={formStyle}
              value={previouslyTypedUsername}
            />
            <FormControl
              margin="normal"
              variant="standard"
              required
              fullWidth
              sx={formStyle}
              style={{ position: "relative" }}
            >
              <InputLabel htmlFor="oldPassword">{_t("oldPassword")}</InputLabel>
              <Input
                name="oldPassword"
                type={viewOldPassword ? "text" : "password"}
                id="oldPassword"
                autoComplete="oldPassword"
                value={oldPassword || ""}
                onChange={updateOldPassword}
              />
              {viewOldPassword ? (
                <VisibilityOff
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleOldPasswordVisibility}
                />
              ) : (
                <Visibility
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleOldPasswordVisibility}
                />
              )}
            </FormControl>
            <FormControl
              margin="normal"
              variant="standard"
              required
              fullWidth
              sx={formStyle}
              error={passwordInvalid}
              style={{ position: "relative" }}
            >
              <InputLabel htmlFor="newPassword">{_t("newPassword")}</InputLabel>
              <Input
                name="newPassword"
                type={viewNewPassword ? "text" : "password"}
                id="oldPasswornenewPasswordwPasswordd"
                autoComplete="newPassword"
                value={password || ""}
                onChange={updatePassword}
              />
              {viewNewPassword ? (
                <VisibilityOff
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleNewPasswordVisibility}
                />
              ) : (
                <Visibility
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleNewPasswordVisibility}
                />
              )}
              {passwordInvalid && (
                <FormHelperText>
                  <PasswordPolicyError />
                </FormHelperText>
              )}
            </FormControl>
            <FormControl
              margin="normal"
              variant="standard"
              required
              fullWidth
              sx={formStyle}
              error={passwordsDoNotMatch}
              style={{ position: "relative" }}
            >
              <InputLabel htmlFor="repeatNewPassword">
                {_t("repeatNewPassword")}
              </InputLabel>
              <Input
                name="repeatNewPassword"
                type={viewNewPassword ? "text" : "password"}
                id="repeatNewPassword"
                autoComplete="repeatNewPassword"
                value={repeatPassword || ""}
                onChange={updateRepeatPassword}
              />
              {viewNewPassword ? (
                <VisibilityOff
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleNewPasswordVisibility}
                />
              ) : (
                <Visibility
                  style={{
                    position: "absolute",
                    top: "15px",
                    right: "10px",
                    cursor: "pointer",
                  }}
                  onClick={toggleNewPasswordVisibility}
                />
              )}
              {passwordsDoNotMatch && (
                <FormHelperText>
                  <PasswordMatchError />
                </FormHelperText>
              )}
            </FormControl>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className="Submit"
              disabled={!oldPassword || passwordsDoNotMatch || passwordInvalid}
              sx={submitStyle}
            >
              {_t("submit")}
            </Button>
          </form>
        </Fragment>
      </Paper>
    </Box>
  );
};
